import { StorytellLogo } from "~/components/StorytellLogo";
import styles from "./SignupFormScreen.module.css";
import { classNames } from "~/lib/classNames";
import { MarketingCTA } from "~/components/cta/MarketingCTA";
import { StBrandGoogle, StIcon } from "~/components/icons";
import { TbCircleCheckFilled, TbSparkles } from "solid-icons/tb";
import { OrDivider } from "~/components/_original/OrDivider";
import { A } from "@solidjs/router";
import { Tooltip, TooltipTrigger } from "~/components/_original/Tooltip";
import { MarketingGradientBlob } from "~/domains/marketing/components/MarketingGradientBlob";
import { For, Show, type Component } from "solid-js";
import { MagicLinkSentScreen } from "./MagicLinkSentScreen";

/**
 * Extracted the signup card UI into a separate component in preparation
 * for gating features behind auth modals that will reuse this.
 */
export const SignupFormScreen: Component<{
  onMagicLink: (email: string) => void;
  onGoogleSignup: () => void;
  onLogin?: () => void;
  email: string;
  status: "idle" | "loading" | "sent";
  invite: string | undefined;
  setEmail: (email: string) => void;
}> = (props) => {
  const title = () => {
    if (props.invite) {
      return "You've been invited to join a collection on Storytell!";
    }
    return "Start finding the signal in your noisy data for free.";
  };

  return (
    <Show when={props.status !== "sent"} fallback={<MagicLinkSentScreen />}>
      <div class={classNames("dark", styles["signup"])}>
        <div class={styles["signup__card"]}>
          <MarketingGradientBlob right />

          <StorytellLogo />
          <p class={styles["signup__subtitle"]}>{title()}</p>

          <div class={styles["signup__options"]}>
            <form
              class={styles["signup__form"]}
              onSubmit={(e) => {
                e.preventDefault();
                props.onMagicLink(e.currentTarget.email.value);
              }}
            >
              <input
                value={props.email}
                onInput={(e) => props.setEmail(e.currentTarget.value)}
                required
                type="email"
                name="email"
                id="email"
                placeholder="your@email.com"
                class={styles["signup__form-input"]}
              />

              <Tooltip
                theme="light"
                placement="bottom"
                content={
                  <p>
                    Enter your email, and we’ll send you a secure link.
                    <br /> Click it to log in–no password needed.
                  </p>
                }
              >
                <TooltipTrigger as="div">
                  <MarketingCTA
                    disabled={props.status === "loading" || props.status === "sent"}
                    type="submit"
                    icon={TbSparkles}
                    compact
                    centered
                    iconPosition="left"
                    square
                    class="!w-full"
                  >
                    Email me a Magic Link
                  </MarketingCTA>
                </TooltipTrigger>
              </Tooltip>
            </form>
            <OrDivider />

            <MarketingCTA
              icon={StBrandGoogle}
              disabled={props.status === "loading" || props.status === "sent"}
              compact
              centered
              iconPosition="left"
              square
              dark
              class={styles["signup__google"]}
              onClick={props.onGoogleSignup}
            >
              Sign up with Google
            </MarketingCTA>

            <p class={styles["signup__login-link"]}>
              Already have an account?{" "}
              <A onClick={props.onLogin} href={props.onLogin ? "#" : "/auth/login"}>
                Log in
              </A>
            </p>
          </div>

          <p class={styles["signup__footer"]}>
            By creating an account, you agree to Storytell's{" "}
            <a target="_blank" rel="noreferrer" href="https://web.storytell.ai/terms-of-service">
              Terms of Service
            </a>{" "}
            and{" "}
            <a target="_blank" rel="noreferrer" href="https://web.storytell.ai/privacy-policy">
              Privacy Policy
            </a>
            . <br />
            You also agree to receive periodic marketing emails, which you can unsubscribe from at any time.
          </p>
        </div>

        <div class={styles["signup__social"]}>
          {/* <MarketingGradientBlob green /> */}
          <p class={styles["signup__social-title"]}>
            The most innovative teams and companies use Storytell to work smarter and get things done faster.
          </p>

          <ul class={styles["signup__social-list"]}>
            <li class={styles["signup__social-list-item"]}>
              <StIcon icon={TbCircleCheckFilled} size={18} />
              <span>Uncover hidden trends & opportunities in data</span>
            </li>
            <li class={styles["signup__social-list-item"]}>
              <StIcon icon={TbCircleCheckFilled} size={18} />
              <span>Automate data analysis & reporting</span>
            </li>
            <li class={styles["signup__social-list-item"]}>
              <StIcon icon={TbCircleCheckFilled} size={18} />
              <span>Empower strategic decision-making with smarter insights </span>
            </li>
          </ul>

          <TrustedBy />
        </div>
      </div>
    </Show>
  );
};

const TrustedBy = () => {
  const data = [
    {
      src: "/images/home/paramount.svg",
      alt: "Paramount",
    },
    {
      src: "/images/home/shopify.svg",
      alt: "Shopify",
    },
    {
      src: "/images/home/pwc.svg",
      alt: "pwc",
    },
    {
      src: "/images/home/cloudflare.svg",
      alt: "Cloudflare",
    },
  ];
  return (
    <div class={styles["trusted-by-banner-logos"]}>
      <For each={data}>{(item) => <img class={styles["trusted-by-banner-logo"]} src={item.src} alt={item.alt} />}</For>
    </div>
  );
};
